import React, { useState, useCallback, memo } from 'react';
import { func, string } from 'prop-types';
import { BiArrowBack } from 'react-icons/bi';
import {
  Box,
  Heading,
  Text,
  FormControl,
  Input,
  Checkbox,
  FormLabel,
  Textarea,
  Button,
  Center,
  useToast,
  MenuButton,
  Menu,
  InputGroup,
  Icon,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';

import useFormFields from '@/hooks/useFormFields';
import useGiftConfirmation from '@/usecase/use-gift-confirmation';
import { COUNTRY_CODES } from '@/constants/country-codes';
import { BiChevronDown } from 'react-icons/bi';
import { useGuest } from '@/context/guest';
import useInvitation from '@/hooks/useInvitation';

import { BG_COVER } from '@/constants/assets';
import {
  ENABLE_SEND_GIFT,
  ENABLE_BANK_TRANSFER,
  DEFAULT_LANGUAGE,
} from '@/constants/feature-flags';
import txtWording from './locales';

import { INPUT_COMMON_PROPS, FORM_LABEL_PROPS, CHECKBOX_PROPS, SELECT_COMMON_PROPS } from './types';

const MemoPrefixNumber = memo(function PrefixNumber(props) {
  const { selectedCountryCode, handleCountryCodeChange } = props;

  return (
    <Menu>
      <MenuButton {...SELECT_COMMON_PROPS} as={Button} rightIcon={<Icon as={BiChevronDown} />}>
        {`+${selectedCountryCode}`}
      </MenuButton>
      <MenuList maxHeight="200px" overflowY="scroll" zIndex={3}>
        {COUNTRY_CODES.map((country, index) => (
          <MenuItem
            key={country.dial_code + index}
            onClick={() => handleCountryCodeChange(country.dial_code)}
          >
            {`${country.name}: +${country.dial_code}`}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
});

const MemoPhoneNumberList = memo(function PhoneNumberList(props) {
  const { createChangeHandler, selectedCountryCode, handleCountryCodeChange, lang, value } = props;

  return (
    <FormControl>
      <FormLabel {...FORM_LABEL_PROPS}>{txtWording.hp[lang]}:</FormLabel>
      <InputGroup size="sm">
        <MemoPrefixNumber
          selectedCountryCode={selectedCountryCode}
          handleCountryCodeChange={handleCountryCodeChange}
        />
        <Input
          {...INPUT_COMMON_PROPS}
          placeholder="..."
          type="number"
          value={value}
          onChange={createChangeHandler('phone_number')}
        />
      </InputGroup>
    </FormControl>
  );
});

MemoPrefixNumber.propTypes = {
  selectedCountryCode: string,
  handleCountryCodeChange: func.isRequired,
};

MemoPhoneNumberList.propTypes = {
  createChangeHandler: func.isRequired,
  selectedCountryCode: string,
  handleCountryCodeChange: func.isRequired,
  lang: string,
  value: string,
};

function Confirmation({ lang, onBack }) {
  const [isGiftCard, setIsGiftCard] = useState(false);
  const [isBankTransfer, setIsBankTransfer] = useState(false);

  const toast = useToast();
  const isInvitation = useInvitation();
  const { onPostConfirmation, loading } = useGiftConfirmation();
  const { guest, isRegistered } = useGuest();
  const { name, phone_number } = guest;

  const { formFields, createChangeHandler, onResetForm } = useFormFields({
    name: `${isRegistered ? name : isInvitation ? '' : name}`,
    isGift: false,
    note: '',
    phone_number: phone_number,
  });

  const [selectedCountryCode, setSelectedCountryCode] = useState(guest.country_code || '62');

  const handleCountryCodeChange = useCallback((code) => {
    setSelectedCountryCode(code);
  }, []);

  const handleSendConfirmation = async () => {
    if (!formFields.name) {
      toast({
        title: 'Oops!',
        description: txtWording.fillNameFirst[lang],
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    await onPostConfirmation({
      name: formFields.name,
      countryCode: selectedCountryCode,
      phone_number: formFields.phone_number,
      notes: formFields.note,
      isBankTransfer: String(isBankTransfer).toUpperCase(),
      isGiftCard: String(isGiftCard).toUpperCase(),
      onSuccess: () => {
        onResetForm();
        toast({
          title: txtWording.submitted[lang],
          description: txtWording.successSent[lang],
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: 'Oops!',
          description: txtWording.failedSent[lang],
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    });
  };

  return (
    <Box
      bgImage={`url(${BG_COVER})`}
      bgSize="cover"
      minHeight="100vh"
      bgPosition="center"
      padding="32px"
    >
      <Box bgColor="white" padding="24px 16px" borderRadius="16px" boxShadow="lg">
        {/* Heading & Desc Section */}
        <Box textAlign="center" color="mainColorText">
          <Heading color="secondaryColorText" fontWeight="normal" fontSize="4xl">
            {txtWording.title[lang]}
          </Heading>
          <Text color="secondaryColorText" fontSize="sm" padding="16px 0 " fontFamily="serif">
            {txtWording.desc[lang]}
          </Text>
        </Box>
        <Box>
          <FormControl margin="8px 0">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.name[lang]}:</FormLabel>
            <Input
              {...INPUT_COMMON_PROPS}
              value={formFields.name}
              onChange={createChangeHandler('name')}
            />
          </FormControl>
          <MemoPhoneNumberList
            value={formFields.phone_number}
            createChangeHandler={createChangeHandler}
            lang={lang}
            selectedCountryCode={selectedCountryCode}
            handleCountryCodeChange={handleCountryCodeChange}
          />
          <FormControl margin="24px 0" fontFamily="serif">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.giftType[lang]}:</FormLabel>
            {ENABLE_BANK_TRANSFER && (
              <Box>
                <Checkbox
                  value={isBankTransfer}
                  onChange={() => setIsBankTransfer(!isBankTransfer)}
                >
                  <Text {...CHECKBOX_PROPS}>{txtWording.bank[lang]}</Text>
                </Checkbox>
              </Box>
            )}
            {ENABLE_SEND_GIFT && (
              <Box>
                <Checkbox value={isGiftCard} onChange={() => setIsGiftCard(!isGiftCard)}>
                  <Text {...CHECKBOX_PROPS}>{txtWording.gift[lang]}</Text>
                </Checkbox>
              </Box>
            )}
          </FormControl>
          <FormControl margin="8px 0">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.notes[lang]}:</FormLabel>
            <Textarea
              {...INPUT_COMMON_PROPS}
              value={formFields.note}
              onChange={createChangeHandler('note')}
            />
          </FormControl>
        </Box>
        <Box marginTop="24px">
          <Center>
            <Button
              isLoading={loading}
              fontWeight="normal"
              size="sm"
              colorScheme="blackAlpha"
              bgColor="secondaryColorText"
              onClick={handleSendConfirmation}
            >
              {txtWording.sendConfirm[lang]}
            </Button>
          </Center>
        </Box>
      </Box>
      <Box paddingTop="24px">
        <Center>
          <Button
            leftIcon={<BiArrowBack />}
            type="button"
            size="sm"
            fontWeight="normal"
            bgColor="bgSecodary"
            color="btnPrimaryColor"
            onClick={onBack}
          >
            {txtWording.backToHome[lang]}
          </Button>
        </Center>
      </Box>
    </Box>
  );
}

Confirmation.propTypes = {
  lang: string,
  onBack: func.isRequired,
};

Confirmation.defaultProps = {
  lang: DEFAULT_LANGUAGE,
};

export default Confirmation;
